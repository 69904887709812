import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Tooltip } from 'react-tippy'

import {
  Container,
  Content,
  Title,
  State,
  Icon,
  IconContainer,
  TitleContainer,
  TooltipIcon,
  Notifications,
  WarningTitle,
} from './ToDoListTask.styles'
import { FormError } from 'components/Form/Form.styles'
import { AuthProvider } from '@stokr/components-library'

export const ToDoTaskState = {
  REJECTED: 'rejected',
  DENIED: 'rejected',
  DRAFT: 'draft',
  IN_PROGRESS: 'in_progress',
  APPROVED: 'approved',
  SKIPPED: 'skipped',
  REAPPLY: 'reapply',
  NOT_AVAILABLE: 'not_available',
}

const ToDoTask = ({
  title,
  subtitle,
  message,
  state,
  icon,
  link,
  tooltip,
  notificationCount,
  greenNotification,
  openModal,
  disabled,
}) => {
  const [modalOpen, setmodalOpen] = React.useState(false)

  const handleOpenModal = () => {
    if (openModal) {
      setmodalOpen(true)
    }
  }
  return (
    <>
      {link || openModal ? (
        <Link to={link}>
          <Container onClick={handleOpenModal} disabled={disabled}>
            <IconContainer>
              <Icon icon={icon} state={state} />
            </IconContainer>
            <Content>
              <TitleContainer>
                <Title link={link || openModal} state={state}>
                  {title}
                  {subtitle && <WarningTitle>{subtitle}</WarningTitle>}
                </Title>
                {tooltip && (
                  <Tooltip
                    position="top"
                    title={tooltip}
                    theme="light"
                    arrow
                    duration={200}
                  >
                    <TooltipIcon onClick={(e) => e.preventDefault()} />
                  </Tooltip>
                )}
              </TitleContainer>
              {message ? <State>{message}</State> : ''}
            </Content>
          </Container>
        </Link>
      ) : (
        <Container disabled={disabled}>
          <IconContainer>
            <Icon icon={icon} state={state} />
          </IconContainer>
          <Content>
            <TitleContainer>
              <Title state={state}>{title}</Title>
              {notificationCount > 0 && (
                <Notifications green={greenNotification}>
                  {notificationCount}
                </Notifications>
              )}
              {tooltip && (
                <Tooltip
                  position="top"
                  title={tooltip}
                  theme="light"
                  arrow
                  duration={200}
                >
                  <TooltipIcon onClick={(e) => e.preventDefault()} />
                </Tooltip>
              )}
            </TitleContainer>
            {message ? <State>{message}</State> : ''}
          </Content>
        </Container>
      )}
    </>
  )
}

ToDoTask.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  link: PropTypes.any,
  tooltip: PropTypes.string,
  notificationCount: PropTypes.string,
}

ToDoTask.defaultProps = {
  tooltip: '',
  link: undefined,
}

export default ToDoTask
