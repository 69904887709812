export default [
  {
    name: 'Afghanistan',
    ISO2: 'AF',
    ISO3: 'AFG',
  },
  {
    name: 'Aland Islands',
    ISO2: 'AX',
    ISO3: 'ALA',
  },
  {
    name: 'Albania',
    ISO2: 'AL',
    ISO3: 'ALB',
  },
  {
    name: 'Algeria',
    ISO2: 'DZ',
    ISO3: 'DZA',
  },
  {
    name: 'American Samoa',
    ISO2: 'AS',
    ISO3: 'ASM',
  },
  {
    name: 'Andorra',
    ISO2: 'AD',
    ISO3: 'AND',
  },
  {
    name: 'Angola',
    ISO2: 'AO',
    ISO3: 'AGO',
  },
  {
    name: 'Anguilla',
    ISO2: 'AI',
    ISO3: 'AIA',
  },
  {
    name: 'Antarctica',
    ISO2: 'AQ',
    ISO3: 'ATA',
  },
  {
    name: 'Antigua and Barbuda',
    ISO2: 'AG',
    ISO3: 'ATG',
  },
  {
    name: 'Argentina',
    ISO2: 'AR',
    ISO3: 'ARG',
  },
  {
    name: 'Armenia',
    ISO2: 'AM',
    ISO3: 'ARM',
  },
  {
    name: 'Aruba',
    ISO2: 'AW',
    ISO3: 'ABW',
  },
  {
    name: 'Australia',
    ISO2: 'AU',
    ISO3: 'AUS',
  },
  {
    name: 'Austria',
    ISO2: 'AT',
    ISO3: 'AUT',
  },
  {
    name: 'Azerbaijan',
    ISO2: 'AZ',
    ISO3: 'AZE',
  },
  {
    name: 'Bahamas',
    ISO2: 'BS',
    ISO3: 'BHS',
  },
  {
    name: 'Bahrain',
    ISO2: 'BH',
    ISO3: 'BHR',
  },
  {
    name: 'Bangladesh',
    ISO2: 'BD',
    ISO3: 'BGD',
  },
  {
    name: 'Barbados',
    ISO2: 'BB',
    ISO3: 'BRB',
  },
  {
    name: 'Belarus',
    ISO2: 'BY',
    ISO3: 'BLR',
  },
  {
    name: 'Belgium',
    ISO2: 'BE',
    ISO3: 'BEL',
  },
  {
    name: 'Belize',
    ISO2: 'BZ',
    ISO3: 'BLZ',
  },
  {
    name: 'Benin',
    ISO2: 'BJ',
    ISO3: 'BEN',
  },
  {
    name: 'Bermuda',
    ISO2: 'BM',
    ISO3: 'BMU',
  },
  {
    name: 'Bhutan',
    ISO2: 'BT',
    ISO3: 'BTN',
  },
  {
    name: 'Bolivia',
    ISO2: 'BO',
    ISO3: 'BOL',
  },
  {
    name: 'Bonaire, Saint Eustatius and Saba ',
    ISO2: 'BQ',
    ISO3: 'BES',
  },
  {
    name: 'Bosnia and Herzegovina',
    ISO2: 'BA',
    ISO3: 'BIH',
  },
  {
    name: 'Botswana',
    ISO2: 'BW',
    ISO3: 'BWA',
  },
  {
    name: 'Bouvet Island',
    ISO2: 'BV',
    ISO3: 'BVT',
  },
  {
    name: 'Brazil',
    ISO2: 'BR',
    ISO3: 'BRA',
  },
  {
    name: 'British Indian Ocean Territory',
    ISO2: 'IO',
    ISO3: 'IOT',
  },
  {
    name: 'British Virgin Islands',
    ISO2: 'VG',
    ISO3: 'VGB',
  },
  {
    name: 'Brunei',
    ISO2: 'BN',
    ISO3: 'BRN',
  },
  {
    name: 'Bulgaria',
    ISO2: 'BG',
    ISO3: 'BGR',
  },
  {
    name: 'Burkina Faso',
    ISO2: 'BF',
    ISO3: 'BFA',
  },
  {
    name: 'Burundi',
    ISO2: 'BI',
    ISO3: 'BDI',
  },
  {
    name: 'Cambodia',
    ISO2: 'KH',
    ISO3: 'KHM',
  },
  {
    name: 'Cameroon',
    ISO2: 'CM',
    ISO3: 'CMR',
  },
  {
    name: 'Canada',
    ISO2: 'CA',
    ISO3: 'CAN',
  },
  {
    name: 'Cape Verde',
    ISO2: 'CV',
    ISO3: 'CPV',
  },
  {
    name: 'Cayman Islands',
    ISO2: 'KY',
    ISO3: 'CYM',
  },
  {
    name: 'Central African Republic',
    ISO2: 'CF',
    ISO3: 'CAF',
  },
  {
    name: 'Chad',
    ISO2: 'TD',
    ISO3: 'TCD',
  },
  {
    name: 'Chile',
    ISO2: 'CL',
    ISO3: 'CHL',
  },
  {
    name: 'China',
    ISO2: 'CN',
    ISO3: 'CHN',
  },
  {
    name: 'Christmas Island',
    ISO2: 'CX',
    ISO3: 'CXR',
  },
  {
    name: 'Cocos Islands',
    ISO2: 'CC',
    ISO3: 'CCK',
  },
  {
    name: 'Colombia',
    ISO2: 'CO',
    ISO3: 'COL',
  },
  {
    name: 'Comoros',
    ISO2: 'KM',
    ISO3: 'COM',
  },
  {
    name: 'Cook Islands',
    ISO2: 'CK',
    ISO3: 'COK',
  },
  {
    name: 'Costa Rica',
    ISO2: 'CR',
    ISO3: 'CRI',
  },
  {
    name: 'Croatia',
    ISO2: 'HR',
    ISO3: 'HRV',
  },
  {
    name: 'Cuba',
    ISO2: 'CU',
    ISO3: 'CUB',
  },
  {
    name: 'Curacao',
    ISO2: 'CW',
    ISO3: 'CUW',
  },
  {
    name: 'Cyprus',
    ISO2: 'CY',
    ISO3: 'CYP',
  },
  {
    name: 'Czech Republic',
    ISO2: 'CZ',
    ISO3: 'CZE',
  },
  {
    name: 'Democratic Republic of the Congo',
    ISO2: 'CD',
    ISO3: 'COD',
  },
  {
    name: 'Denmark',
    ISO2: 'DK',
    ISO3: 'DNK',
  },
  {
    name: 'Djibouti',
    ISO2: 'DJ',
    ISO3: 'DJI',
  },
  {
    name: 'Dominica',
    ISO2: 'DM',
    ISO3: 'DMA',
  },
  {
    name: 'Dominican Republic',
    ISO2: 'DO',
    ISO3: 'DOM',
  },
  {
    name: 'East Timor',
    ISO2: 'TL',
    ISO3: 'TLS',
  },
  {
    name: 'Ecuador',
    ISO2: 'EC',
    ISO3: 'ECU',
  },
  {
    name: 'Egypt',
    ISO2: 'EG',
    ISO3: 'EGY',
  },
  {
    name: 'El Salvador',
    ISO2: 'SV',
    ISO3: 'SLV',
  },
  {
    name: 'Equatorial Guinea',
    ISO2: 'GQ',
    ISO3: 'GNQ',
  },
  {
    name: 'Eritrea',
    ISO2: 'ER',
    ISO3: 'ERI',
  },
  {
    name: 'Estonia',
    ISO2: 'EE',
    ISO3: 'EST',
  },
  {
    name: 'Ethiopia',
    ISO2: 'ET',
    ISO3: 'ETH',
  },
  {
    name: 'Falkland Islands',
    ISO2: 'FK',
    ISO3: 'FLK',
  },
  {
    name: 'Faroe Islands',
    ISO2: 'FO',
    ISO3: 'FRO',
  },
  {
    name: 'Fiji',
    ISO2: 'FJ',
    ISO3: 'FJI',
  },
  {
    name: 'Finland',
    ISO2: 'FI',
    ISO3: 'FIN',
  },
  {
    name: 'France',
    ISO2: 'FR',
    ISO3: 'FRA',
  },
  {
    name: 'French Guiana',
    ISO2: 'GF',
    ISO3: 'GUF',
  },
  {
    name: 'French Polynesia',
    ISO2: 'PF',
    ISO3: 'PYF',
  },
  {
    name: 'French Southern Territories',
    ISO2: 'TF',
    ISO3: 'ATF',
  },
  {
    name: 'Gabon',
    ISO2: 'GA',
    ISO3: 'GAB',
  },
  {
    name: 'Gambia',
    ISO2: 'GM',
    ISO3: 'GMB',
  },
  {
    name: 'Georgia',
    ISO2: 'GE',
    ISO3: 'GEO',
  },
  {
    name: 'Germany',
    ISO2: 'DE',
    ISO3: 'DEU',
  },
  {
    name: 'Ghana',
    ISO2: 'GH',
    ISO3: 'GHA',
  },
  {
    name: 'Gibraltar',
    ISO2: 'GI',
    ISO3: 'GIB',
  },
  {
    name: 'Greece',
    ISO2: 'GR',
    ISO3: 'GRC',
  },
  {
    name: 'Greenland',
    ISO2: 'GL',
    ISO3: 'GRL',
  },
  {
    name: 'Grenada',
    ISO2: 'GD',
    ISO3: 'GRD',
  },
  {
    name: 'Guadeloupe',
    ISO2: 'GP',
    ISO3: 'GLP',
  },
  {
    name: 'Guam',
    ISO2: 'GU',
    ISO3: 'GUM',
  },
  {
    name: 'Guatemala',
    ISO2: 'GT',
    ISO3: 'GTM',
  },
  {
    name: 'Guernsey',
    ISO2: 'GG',
    ISO3: 'GGY',
  },
  {
    name: 'Guinea',
    ISO2: 'GN',
    ISO3: 'GIN',
  },
  {
    name: 'Guinea-Bissau',
    ISO2: 'GW',
    ISO3: 'GNB',
  },
  {
    name: 'Guyana',
    ISO2: 'GY',
    ISO3: 'GUY',
  },
  {
    name: 'Haiti',
    ISO2: 'HT',
    ISO3: 'HTI',
  },
  {
    name: 'Heard Island and McDonald Islands',
    ISO2: 'HM',
    ISO3: 'HMD',
  },
  {
    name: 'Honduras',
    ISO2: 'HN',
    ISO3: 'HND',
  },
  {
    name: 'Hong Kong',
    ISO2: 'HK',
    ISO3: 'HKG',
  },
  {
    name: 'Hungary',
    ISO2: 'HU',
    ISO3: 'HUN',
  },
  {
    name: 'Iceland',
    ISO2: 'IS',
    ISO3: 'ISL',
  },
  {
    name: 'India',
    ISO2: 'IN',
    ISO3: 'IND',
  },
  {
    name: 'Indonesia',
    ISO2: 'ID',
    ISO3: 'IDN',
  },
  {
    name: 'Iran',
    ISO2: 'IR',
    ISO3: 'IRN',
  },
  {
    name: 'Iraq',
    ISO2: 'IQ',
    ISO3: 'IRQ',
  },
  {
    name: 'Ireland',
    ISO2: 'IE',
    ISO3: 'IRL',
  },
  {
    name: 'Isle of Man',
    ISO2: 'IM',
    ISO3: 'IMN',
  },
  {
    name: 'Israel',
    ISO2: 'IL',
    ISO3: 'ISR',
  },
  {
    name: 'Italy',
    ISO2: 'IT',
    ISO3: 'ITA',
  },
  {
    name: 'Ivory Coast',
    ISO2: 'CI',
    ISO3: 'CIV',
  },
  {
    name: 'Jamaica',
    ISO2: 'JM',
    ISO3: 'JAM',
  },
  {
    name: 'Japan',
    ISO2: 'JP',
    ISO3: 'JPN',
  },
  {
    name: 'Jersey',
    ISO2: 'JE',
    ISO3: 'JEY',
  },
  {
    name: 'Jordan',
    ISO2: 'JO',
    ISO3: 'JOR',
  },
  {
    name: 'Kazakhstan',
    ISO2: 'KZ',
    ISO3: 'KAZ',
  },
  {
    name: 'Kenya',
    ISO2: 'KE',
    ISO3: 'KEN',
  },
  {
    name: 'Kiribati',
    ISO2: 'KI',
    ISO3: 'KIR',
  },
  {
    name: 'Kosovo',
    ISO2: 'XK',
    ISO3: 'XKX',
  },
  {
    name: 'Kuwait',
    ISO2: 'KW',
    ISO3: 'KWT',
  },
  {
    name: 'Kyrgyzstan',
    ISO2: 'KG',
    ISO3: 'KGZ',
  },
  {
    name: 'Laos',
    ISO2: 'LA',
    ISO3: 'LAO',
  },
  {
    name: 'Latvia',
    ISO2: 'LV',
    ISO3: 'LVA',
  },
  {
    name: 'Lebanon',
    ISO2: 'LB',
    ISO3: 'LBN',
  },
  {
    name: 'Lesotho',
    ISO2: 'LS',
    ISO3: 'LSO',
  },
  {
    name: 'Liberia',
    ISO2: 'LR',
    ISO3: 'LBR',
  },
  {
    name: 'Libya',
    ISO2: 'LY',
    ISO3: 'LBY',
  },
  {
    name: 'Liechtenstein',
    ISO2: 'LI',
    ISO3: 'LIE',
  },
  {
    name: 'Lithuania',
    ISO2: 'LT',
    ISO3: 'LTU',
  },
  {
    name: 'Luxembourg',
    ISO2: 'LU',
    ISO3: 'LUX',
  },
  {
    name: 'Macao',
    ISO2: 'MO',
    ISO3: 'MAC',
  },
  {
    name: 'Macedonia',
    ISO2: 'MK',
    ISO3: 'MKD',
  },
  {
    name: 'Madagascar',
    ISO2: 'MG',
    ISO3: 'MDG',
  },
  {
    name: 'Malawi',
    ISO2: 'MW',
    ISO3: 'MWI',
  },
  {
    name: 'Malaysia',
    ISO2: 'MY',
    ISO3: 'MYS',
  },
  {
    name: 'Maldives',
    ISO2: 'MV',
    ISO3: 'MDV',
  },
  {
    name: 'Mali',
    ISO2: 'ML',
    ISO3: 'MLI',
  },
  {
    name: 'Malta',
    ISO2: 'MT',
    ISO3: 'MLT',
  },
  {
    name: 'Marshall Islands',
    ISO2: 'MH',
    ISO3: 'MHL',
  },
  {
    name: 'Martinique',
    ISO2: 'MQ',
    ISO3: 'MTQ',
  },
  {
    name: 'Mauritania',
    ISO2: 'MR',
    ISO3: 'MRT',
  },
  {
    name: 'Mauritius',
    ISO2: 'MU',
    ISO3: 'MUS',
  },
  {
    name: 'Mayotte',
    ISO2: 'YT',
    ISO3: 'MYT',
  },
  {
    name: 'Mexico',
    ISO2: 'MX',
    ISO3: 'MEX',
  },
  {
    name: 'Micronesia',
    ISO2: 'FM',
    ISO3: 'FSM',
  },
  {
    name: 'Moldova',
    ISO2: 'MD',
    ISO3: 'MDA',
  },
  {
    name: 'Monaco',
    ISO2: 'MC',
    ISO3: 'MCO',
  },
  {
    name: 'Mongolia',
    ISO2: 'MN',
    ISO3: 'MNG',
  },
  {
    name: 'Montenegro',
    ISO2: 'ME',
    ISO3: 'MNE',
  },
  {
    name: 'Montserrat',
    ISO2: 'MS',
    ISO3: 'MSR',
  },
  {
    name: 'Morocco',
    ISO2: 'MA',
    ISO3: 'MAR',
  },
  {
    name: 'Mozambique',
    ISO2: 'MZ',
    ISO3: 'MOZ',
  },
  {
    name: 'Myanmar',
    ISO2: 'MM',
    ISO3: 'MMR',
  },
  {
    name: 'Namibia',
    ISO2: 'NA',
    ISO3: 'NAM',
  },
  {
    name: 'Nauru',
    ISO2: 'NR',
    ISO3: 'NRU',
  },
  {
    name: 'Nepal',
    ISO2: 'NP',
    ISO3: 'NPL',
  },
  {
    name: 'Netherlands',
    ISO2: 'NL',
    ISO3: 'NLD',
  },
  {
    name: 'New Caledonia',
    ISO2: 'NC',
    ISO3: 'NCL',
  },
  {
    name: 'New Zealand',
    ISO2: 'NZ',
    ISO3: 'NZL',
  },
  {
    name: 'Nicaragua',
    ISO2: 'NI',
    ISO3: 'NIC',
  },
  {
    name: 'Niger',
    ISO2: 'NE',
    ISO3: 'NER',
  },
  {
    name: 'Nigeria',
    ISO2: 'NG',
    ISO3: 'NGA',
  },
  {
    name: 'Niue',
    ISO2: 'NU',
    ISO3: 'NIU',
  },
  {
    name: 'Norfolk Island',
    ISO2: 'NF',
    ISO3: 'NFK',
  },
  {
    name: 'North Korea',
    ISO2: 'KP',
    ISO3: 'PRK',
  },
  {
    name: 'Northern Mariana Islands',
    ISO2: 'MP',
    ISO3: 'MNP',
  },
  {
    name: 'Norway',
    ISO2: 'NO',
    ISO3: 'NOR',
  },
  {
    name: 'Oman',
    ISO2: 'OM',
    ISO3: 'OMN',
  },
  {
    name: 'Pakistan',
    ISO2: 'PK',
    ISO3: 'PAK',
  },
  {
    name: 'Palau',
    ISO2: 'PW',
    ISO3: 'PLW',
  },
  {
    name: 'Palestinian Territory',
    ISO2: 'PS',
    ISO3: 'PSE',
  },
  {
    name: 'Panama',
    ISO2: 'PA',
    ISO3: 'PAN',
  },
  {
    name: 'Papua New Guinea',
    ISO2: 'PG',
    ISO3: 'PNG',
  },
  {
    name: 'Paraguay',
    ISO2: 'PY',
    ISO3: 'PRY',
  },
  {
    name: 'Peru',
    ISO2: 'PE',
    ISO3: 'PER',
  },
  {
    name: 'Philippines',
    ISO2: 'PH',
    ISO3: 'PHL',
  },
  {
    name: 'Pitcairn',
    ISO2: 'PN',
    ISO3: 'PCN',
  },
  {
    name: 'Poland',
    ISO2: 'PL',
    ISO3: 'POL',
  },
  {
    name: 'Portugal',
    ISO2: 'PT',
    ISO3: 'PRT',
  },
  {
    name: 'Puerto Rico',
    ISO2: 'PR',
    ISO3: 'PRI',
  },
  {
    name: 'Qatar',
    ISO2: 'QA',
    ISO3: 'QAT',
  },
  {
    name: 'Republic of the Congo',
    ISO2: 'CG',
    ISO3: 'COG',
  },
  {
    name: 'Reunion',
    ISO2: 'RE',
    ISO3: 'REU',
  },
  {
    name: 'Romania',
    ISO2: 'RO',
    ISO3: 'ROU',
  },
  {
    name: 'Russia',
    ISO2: 'RU',
    ISO3: 'RUS',
  },
  {
    name: 'Rwanda',
    ISO2: 'RW',
    ISO3: 'RWA',
  },
  {
    name: 'Saint Barthelemy',
    ISO2: 'BL',
    ISO3: 'BLM',
  },
  {
    name: 'Saint Helena',
    ISO2: 'SH',
    ISO3: 'SHN',
  },
  {
    name: 'Saint Kitts and Nevis',
    ISO2: 'KN',
    ISO3: 'KNA',
  },
  {
    name: 'Saint Lucia',
    ISO2: 'LC',
    ISO3: 'LCA',
  },
  {
    name: 'Saint Martin',
    ISO2: 'MF',
    ISO3: 'MAF',
  },
  {
    name: 'Saint Pierre and Miquelon',
    ISO2: 'PM',
    ISO3: 'SPM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    ISO2: 'VC',
    ISO3: 'VCT',
  },
  {
    name: 'Samoa',
    ISO2: 'WS',
    ISO3: 'WSM',
  },
  {
    name: 'San Marino',
    ISO2: 'SM',
    ISO3: 'SMR',
  },
  {
    name: 'Sao Tome and Principe',
    ISO2: 'ST',
    ISO3: 'STP',
  },
  {
    name: 'Saudi Arabia',
    ISO2: 'SA',
    ISO3: 'SAU',
  },
  {
    name: 'Senegal',
    ISO2: 'SN',
    ISO3: 'SEN',
  },
  {
    name: 'Serbia',
    ISO2: 'RS',
    ISO3: 'SRB',
  },
  {
    name: 'Seychelles',
    ISO2: 'SC',
    ISO3: 'SYC',
  },
  {
    name: 'Sierra Leone',
    ISO2: 'SL',
    ISO3: 'SLE',
  },
  {
    name: 'Singapore',
    ISO2: 'SG',
    ISO3: 'SGP',
  },
  {
    name: 'Sint Maarten',
    ISO2: 'SX',
    ISO3: 'SXM',
  },
  {
    name: 'Slovakia',
    ISO2: 'SK',
    ISO3: 'SVK',
  },
  {
    name: 'Slovenia',
    ISO2: 'SI',
    ISO3: 'SVN',
  },
  {
    name: 'Solomon Islands',
    ISO2: 'SB',
    ISO3: 'SLB',
  },
  {
    name: 'Somalia',
    ISO2: 'SO',
    ISO3: 'SOM',
  },
  {
    name: 'South Africa',
    ISO2: 'ZA',
    ISO3: 'ZAF',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    ISO2: 'GS',
    ISO3: 'SGS',
  },
  {
    name: 'South Korea',
    ISO2: 'KR',
    ISO3: 'KOR',
  },
  {
    name: 'South Sudan',
    ISO2: 'SS',
    ISO3: 'SSD',
  },
  {
    name: 'Spain',
    ISO2: 'ES',
    ISO3: 'ESP',
  },
  {
    name: 'Sri Lanka',
    ISO2: 'LK',
    ISO3: 'LKA',
  },
  {
    name: 'Sudan',
    ISO2: 'SD',
    ISO3: 'SDN',
  },
  {
    name: 'Suriname',
    ISO2: 'SR',
    ISO3: 'SUR',
  },
  {
    name: 'Svalbard and Jan Mayen',
    ISO2: 'SJ',
    ISO3: 'SJM',
  },
  {
    name: 'Swaziland',
    ISO2: 'SZ',
    ISO3: 'SWZ',
  },
  {
    name: 'Sweden',
    ISO2: 'SE',
    ISO3: 'SWE',
  },
  {
    name: 'Switzerland',
    ISO2: 'CH',
    ISO3: 'CHE',
  },
  {
    name: 'Syria',
    ISO2: 'SY',
    ISO3: 'SYR',
  },
  {
    name: 'Taiwan',
    ISO2: 'TW',
    ISO3: 'TWN',
  },
  {
    name: 'Tajikistan',
    ISO2: 'TJ',
    ISO3: 'TJK',
  },
  {
    name: 'Tanzania',
    ISO2: 'TZ',
    ISO3: 'TZA',
  },
  {
    name: 'Thailand',
    ISO2: 'TH',
    ISO3: 'THA',
  },
  {
    name: 'Togo',
    ISO2: 'TG',
    ISO3: 'TGO',
  },
  {
    name: 'Tokelau',
    ISO2: 'TK',
    ISO3: 'TKL',
  },
  {
    name: 'Tonga',
    ISO2: 'TO',
    ISO3: 'TON',
  },
  {
    name: 'Trinidad and Tobago',
    ISO2: 'TT',
    ISO3: 'TTO',
  },
  {
    name: 'Tunisia',
    ISO2: 'TN',
    ISO3: 'TUN',
  },
  {
    name: 'Turkey',
    ISO2: 'TR',
    ISO3: 'TUR',
  },
  {
    name: 'Turkmenistan',
    ISO2: 'TM',
    ISO3: 'TKM',
  },
  {
    name: 'Turks and Caicos Islands',
    ISO2: 'TC',
    ISO3: 'TCA',
  },
  {
    name: 'Tuvalu',
    ISO2: 'TV',
    ISO3: 'TUV',
  },
  {
    name: 'U.S. Virgin Islands',
    ISO2: 'VI',
    ISO3: 'VIR',
  },
  {
    name: 'Uganda',
    ISO2: 'UG',
    ISO3: 'UGA',
  },
  {
    name: 'Ukraine',
    ISO2: 'UA',
    ISO3: 'UKR',
  },
  {
    name: 'United Arab Emirates',
    ISO2: 'AE',
    ISO3: 'ARE',
  },
  {
    name: 'United Kingdom',
    ISO2: 'GB',
    ISO3: 'GBR',
  },
  {
    name: 'United States',
    ISO2: 'US',
    ISO3: 'USA',
  },
  {
    name: 'United States Minor Outlying Islands',
    ISO2: 'UM',
    ISO3: 'UMI',
  },
  {
    name: 'Uruguay',
    ISO2: 'UY',
    ISO3: 'URY',
  },
  {
    name: 'Uzbekistan',
    ISO2: 'UZ',
    ISO3: 'UZB',
  },
  {
    name: 'Vanuatu',
    ISO2: 'VU',
    ISO3: 'VUT',
  },
  {
    name: 'Vatican',
    ISO2: 'VA',
    ISO3: 'VAT',
  },
  {
    name: 'Venezuela',
    ISO2: 'VE',
    ISO3: 'VEN',
  },
  {
    name: 'Vietnam',
    ISO2: 'VN',
    ISO3: 'VNM',
  },
  {
    name: 'Wallis and Futuna',
    ISO2: 'WF',
    ISO3: 'WLF',
  },
  {
    name: 'Western Sahara',
    ISO2: 'EH',
    ISO3: 'ESH',
  },
  {
    name: 'Yemen',
    ISO2: 'YE',
    ISO3: 'YEM',
  },
  {
    name: 'Zambia',
    ISO2: 'ZM',
    ISO3: 'ZMB',
  },
  {
    name: 'Zimbabwe',
    ISO2: 'ZW',
    ISO3: 'ZWE',
  },
]
